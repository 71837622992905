import React, {useEffect, useState} from 'react';
import {Button, Form, Icon, Input, message, Modal} from "antd";
import {Images} from "../../../../Helpers/Images";
import NumberFormat from "react-number-format";

const ModalFormContactUs = (props) => {
    const {TextArea} = Input
    const [loading, setLoading] = useState(false)
    const [dataForm, setDataForm] = useState({
        name: "",
        email: "",
        phone: "",
        msgArea: ""
    })

    const [validateFields, setValidateFields] = useState({
        name: {
            status: "initial",
            message: ""
        },
        email: {
            status: "initial",
            message: "",
        },
        phone: {
            status:"initial",
            message: ""
        },
        msgArea: {
            status:"initial",
            message: ""
        },
    })

    const sendData = () => {
        let aux = {}

        if (dataForm.name === "" ) {
            aux = {...aux, name:{status: "invalid",message:"O campo é obrigatório!"}}
            setValidateFields(aux)
        } else {
            aux = {...aux, name:{status: "initial"}}
            setValidateFields(aux)
        }

        if (dataForm.email === "") {
            aux = {...aux, email: {status: "invalid", message:"O campo é obrigatório!"}}
            setValidateFields(aux)
        } else if (!isEmail(dataForm.email)) {
            aux = {...aux, email: {status:"invalid", message:"Insira um e-mail válido"}}
            setValidateFields(aux)
        }

        else {
            aux = {...aux, email:{status:"initial"}}
            setValidateFields(aux)

        }

        if (dataForm.phone.length < 10) {
            aux = {...aux, phone: {status:"invalid", message:"O campo é obrigatório!"}}
            setValidateFields(aux)
        } else {
            aux = {...aux, phone: {status:"initial"}}
            setValidateFields(aux)
        }

        if (dataForm.msgArea === "") {
            aux = {...aux, msgArea: {status:"invalid", message:"O campo é obrigatório!"}}
            setValidateFields(aux)
        } else {
            aux = {...aux, msgArea: {status:"initial"}}
            setValidateFields(aux)
        }

        if (aux.name.status === "initial" && aux.email.status === "initial" && aux.phone.status === "initial" && aux.msgArea.status === "initial" ) {
            setLoading(true)

            setTimeout (()=> {
                message.success("Sua mensagem foi enviada com sucesso, logo entraremos em contato!")
                setDataForm({name:"", email: "", phone: "", msgArea: ""})
                props.handleClose()
                setLoading(false)

            }, 2000)
        } else {
            message.warning("Preencha todos os campos corretamente.")
        }
    }
    function isEmail (email) {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

    const handleChange = (label, value) => {
        setDataForm({...dataForm, [label]: value})
        if(value && value.length > 0) {
            setValidateFields({...validateFields, [label]:{status: "initial"}})
        } else {
            setValidateFields({...validateFields, [label]:{status: "invalid", message:"O campo é obrigatório!"}})
        }
    }
    return (

        <div>
            <Modal
                visible={props.visible}
                title="Nos envie um e-mail"
                onOk={sendData}
                onCancel={props.handleClose}
                width={"65%"}
                footer={[
                    <Button key="back" onClick={props.handleClose}>
                        Cancelar
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={sendData}>
                        Enviar
                    </Button>,
                ]}
            >
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div style={{marginRight: 24}} className="imageFormModal">
                        <img src={Images.formModal} alt="Formulário"/>
                    </div>
                    <Form style={{width: "100%"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div id="name" label="Nome completo" className={validateFields.name.status === "initial" ? "formControlModal" : "formControlModal err "}>Nome completo:

                                    <Input  type="text" value={dataForm.name} placeholder="Qual seu nome?"
                                           onChange={(e) => {
                                               handleChange(`name`, e.target.value)
                                           }}
                                    />
                                    <Icon type="exclamation-circle" theme="filled" className={validateFields.name.status === "initial" ? "formControlModal" : "formControlModal err exclamation-circle-red"} />
                                    <small>{validateFields.name.message}</small>
                            </div>

                            <div htmlFor="email" label="E-mail" className={validateFields.email.status === "initial" ? "formControlModal" : "formControlModal err"}>Email:
                                <Input id="email" placeholder="Qual seu e-mail?" type="email" value={dataForm.email}
                                          onChange={(e) => {
                                              handleChange(`email`, e.target.value)
                                          }}
                                />
                                <Icon type="exclamation-circle" theme="filled" className={validateFields.email.status === "initial" ? "formControlModal" : "formControlModal err exclamation-circle-red"}/>
                                <small>{validateFields.email.message}</small>
                            </div>

                            <div htmlFor="tel" label="Telefone" className={validateFields.phone.status === "initial" ? "formControlModal" : "formControlModal err"}>Telefone:
                                <NumberFormat
                                    id="tel"
                                    type={"tel"}
                                    value={dataForm.phone}
                                    onValueChange={(e) => {
                                        setDataForm({...dataForm, phone:e.value})
                                        setValidateFields({...validateFields, phone:{status: "initial"}})
                                    }}
                                    placeholder={'(99) 99999-9999'}
                                    className='ant-input ant-input-lg'
                                    format={'(##) #####-####'} mask="_"/>
                                    <Icon type="exclamation-circle" theme="filled" className={validateFields.phone.status === "initial" ? "formControlModal" : "formControlModal err exclamation-circle-red"}/>
                                    <small>{validateFields.phone.message}</small>
                            </div>

                            <div htmlFor="msgArea" label="Insira sua mensagem" className={validateFields.msgArea.status === "initial" ? "formControlModal" : "formControlModal err"}>Mensagem:
                                <TextArea rows={5} id="msgArea" type="text" placeholder="Nos diga aqui, como podemos te ajudar?" value={dataForm.msgArea}
                                             onChange={(e)=>{
                                                 handleChange(`msgArea`, e.target.value)

                                             }}
                                />
                                <Icon type="exclamation-circle" theme="filled" className={validateFields.msgArea.status === "initial" ? "formControlModal" : "formControlModal err exclamation-circle-red"}/>
                                <small>{validateFields.msgArea.message}</small>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>

    )
}

export default ModalFormContactUs