import React, {useState} from 'react';
import {Rate, Col, Icon, Row, Carousel} from "antd";
import {Texts} from "../../../../Helpers/Texts";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Images} from "../../../../Helpers/Images";


const TestimonialComponent = () => {
    const testimonials = [
        {
            name: `John Wick`,
            score: `5`,
            title: `Very good!!`,
            text: `Amazing site, "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `worker`
        },
        {
            name: `Bruno Wick`,
            score: `4`,
            title: `I liked it`,
            text: `Ased do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `entrepreneur`
        },
        {
            name: `Luiz Wick`,
            score: `2`,
            title: `Very intuitive and simple to use!`,
            text: `Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `worker`
        },
        {
            name: `Ted Wick`,
            score: `4`,
            title: `Very good!!`,
            text: `Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `entrepreneur`
        },
        {
            name: `Luiz Wick`,
            score: `2`,
            title: `Very intuitive and simple to use!`,
            text: `Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `worker`
        },
        {
            name: `Luiz Wick`,
            score: `2`,
            title: `Very intuitive and simple to use!`,
            text: `Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
            type: `worker`
        },

    ]

    const [cardIndex, setCardIndex] = useState(0)

    const NextArrow = ({onClick}) => {
        return (
            <div className="arrowTestimonial next" onClick={onClick}>
                <Icon type="arrow-right" />
            </div>
            )
    }
    const PrevArrow = ({onClick}) => {
        return (
            <div className="arrowTestimonial prev" onClick={onClick}>
                <Icon type="arrow-left" />
            </div>
        )
    }

    const settings = {
        infinite: true,
        lazyLoad:true,
        slidesToShow:3,
        slidesToScroll:1,
        autoplay: true,
        centerMode: true,
        centerPadding:0,
        speed: 500,
        autoplaySpeed:5000,
        pauseOnHover: true,
        nextArrow: <NextArrow/>,
        prevArrow:<PrevArrow/>,
        beforeChange: (current, next) => setCardIndex(next),
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    pauseOnHover: true,
                    centerPadding:0,
                    centerMode: true,
                    nextArrow: <NextArrow/>,
                    prevArrow:<PrevArrow/>,
                }
            }
        ]

    };

    return (
        <div style={{marginTop:80}}>
            <Row style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Col sm={20} lg={20} xl={19} xxl={16} className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <img style={{filter: 'brightness(0.2)', width: 50, marginBottom: 30}} src={Images.feedbackIconTestimonial}/>
                    <h1 style={{position: 'relative'}} className={'title title-line'}> {Texts.home_page.register.title4} </h1>
                </Col>
            </Row>

            <Slider {...settings}>
                {testimonials.map((testimonial, index) =>
                    <div className={index === cardIndex ? "slide activeSlide testimonialCard" : "slide testimonialCard"}>
                        <div style={{marginTop: 32}}>
                            <h2>{testimonial.name}</h2>
                            {testimonial.type === "worker" ? <p className="colorGreenTestimonial"><Icon type="user" style={{marginRight:8}} />Profissional</p> : <p className="colorBlueTestimonial"><Icon type="shop" style={{marginRight:8}}/>Empresário</p> }
                            {/*<p style={{fontSize:20}} className={testimonial.type === "worker" ?  "colorBlueTestimonial": }>{testimonial.type}</p>*/}
                        </div>

                        <div>
                            <Rate disabled defaultValue={testimonial.score}/>
                            <h3>{testimonial.title}</h3>
                        </div>

                        <div style={{padding: 16}}>
                            <p>{testimonial.text}</p>
                        </div>
                    </div>
                )}
            </Slider>
        </div>
    )
}

export default TestimonialComponent