import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Input} from "antd";
import {Fade} from "react-reveal";
import {Link} from "react-router-dom";
import {Images} from "../../../../Helpers/Images";


const LoginForm = props => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    function responseGoogle(googleUser) {
        if (googleUser.profileObj) {
            props.doGoogleLogin(googleUser.tokenId);
        }
    }

    const responseFacebook = (response) => {
        if (response) {
          props.doFacebookLogin(response.accessToken);
        }
    };


    return (
        <div  style={{paddingTop: '70px'}}>
            <Fade mountOnEnter>
                <form>
                    <div className='loginFormMobile' style={{width: 500, margin: "auto", padding: '30px', borderRadius: '10px'}}>


                        <div className='logo' style={{marginBottom: 50, textAlign: 'center'}}>
                            <img style={{maxWidth: 288}} src={Images.logo}/>
                        </div>

                      {/*  <div className='enterWithButtonIcons'>
                            <FacebookLogin
                                className='facebookButton'
                                appId="152263732198259" //APP ID NOT CREATED YET
                                fields="name,email,picture"
                                callback={(e) => responseFacebook(e)}
                                render={(renderProps) =>
                                    <Button onClick={renderProps.onClick} className='facebookButton'> <Icon type='facebook' style={{color: '#4267b2'}}/>  Entre com o <strong style={{color: '#4267b2'}}> Facebook </strong> </Button>
                                }
                            />
                            <GoogleLogin
                                clientId="272843823543-jb6l311al9vouc1h5k4d2mmj7rd9cms3.apps.googleusercontent.com"
                                buttonText="Entre com o Google"
                                className={'googleButton'}
                                onSuccess={(e) => responseGoogle(e)}
                                onFailure={(e) => responseGoogle(e)}
                            />
                        </div>
                        <Divider> ou </Divider>
                        */}

                        <div>
                            <label style={{fontSize: 17, fontWeight: 'bold'}}> CPF ou Email </label>
                            <Input className='defaultInput' size={'large'}
                                   placeholder={'Seu CPF ou Email'} value={login} onChange={(e) => setLogin(e.target.value)} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                        </div>

                        <div>
                            <label style={{fontSize: 17, fontWeight: 'bold'}}> Senha  </label>
                            <Input className='defaultInput' size={'large'}
                                   type={'password'} placeholder={'Sua Senha'} value={password} onChange={(e) => setPassword(e.target.value)} suffix={<Icon type="lock" className={'primary-sub-color'} style={{fontSize: '17px'}}/>}/>
                        </div>


                        <Button htmlType={'submit'} loading={props.loading} style={{width: '100%', marginTop: 25}} size={'large'} onClick={(e) => props.doLogin(login,password,e)}> Entrar </Button>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 13, fontSize: 15, padding: '0 20px 0 20px'}}>
                        <strong className={'primary-sub-color'} style={{cursor: 'pointer'}} onClick={() => props.setForgot(true)}>Esqueceu sua senha?</strong>
                        <span style={{textAlign: 'right'}}>Não tem uma conta? <Link to={'/registro'} ><strong className={'primary-sub-color'}> Cadastre-se </strong> </Link></span>
                    </div>
                </form>
            </Fade>
        </div>
    );
};

LoginForm.propTypes = {
    doLogin: PropTypes.any,
    doGoogleLogin: PropTypes.any,
    doFacebookLogin: PropTypes.any
};

export default LoginForm;
