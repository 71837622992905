import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Col, Collapse, Divider, Drawer, Form, Icon, message, Modal, notification, Radio, Row, Select, Spin, Tag} from 'antd';
import useApi from "../../../../Hooks/Api";
import * as moment from "moment";
import {globalMaskReal, maskCnpj, maskCpf, maskPhone, maskReal} from "../../../../Helpers/Functions";
import {useSelector} from "react-redux";
import {
    FaBriefcase,
    FaCalendarCheck,
    FaCheck,
    FaClock, FaHandPointer, FaLongArrowAltRight,
    FaMousePointer,
    FaPencilAlt, FaPhone, FaSortAmountUp,
    FaTools, FaUser,
    FaUsers
} from "react-icons/fa";
import {
    FaCodepen,
    FaDollarSign, FaGraduationCap,
    FaIdCardAlt, FaLanguage, FaRegAddressBook,
    FaRegCalendarAlt, FaRegFileAlt, FaRegMap,
    FaRegThumbsUp, MdMail
} from "react-icons/all";
import {Images} from "../../../../Helpers/Images";
import _ from "lodash";
import RegisterPageModal from "../../../../Pages/RegisterPageModal";
import useLocalStorage from "../../../../Hooks/Storage";
import {StorageVariables} from "../../../../Helpers/StoragesVariables";
import {Env} from "../../../../Helpers/Env";
import ModalAlertRegisterCurriculum from "../../../../Pages/Modal/ModalAlertRegisterCurriculum";

const WorkerCourseDrawer = (props, ref) => {
    const [loading, setLoading] = useState(false);
    const api = useApi({customReturn: true, loadingControl: false});
    const [visible, setVisible] = useState(false);
    const [courseCityModal, setCourseCityModal] = useState(false);
    const user = useSelector((state) => state).authReducer;
    const [status, setStatus] = useState(props.course?.subscription_status || 'unsub');
    const [links, setLinks] = useState([]);
    const [links2, setLinks2] = useState([]);
    const [registerModal, setRegisterModal] = useState(false);
    const [alertRegisterCurriculum, setAlertRegisterCurriculum] = useState(false);
    const fullDataUserCurriculum = useLocalStorage(StorageVariables.updatedProfile);

    const handleApply = async (course_city_id) => {
        if (user?.user_id) {
            if(fullDataUserCurriculum.getObject() && fullDataUserCurriculum.getObject()?.user_id !== null) {
                let data = course_city_id? {course_city_id} : null;
                if(props.course?.course_cities?.length > 0 && !course_city_id) {
                    setCourseCityModal(true);
                    return;
                }
                setVisible(false);
                setLoading(true);
                api.post(`courses/subscribe/${props.course.course_id}`, data, async (res) => {
                    setStatus('applied');
                    setLoading(false);
                    if (props.removeFromList) props.removeFromList();
                    if (props.course.redirect_link) {
                        let newWin = window.open(props.course.redirect_link, '_blank');

                        if(!newWin || newWin.closed || typeof newWin.closed=='undefined')
                        {
                            const key = 'notificationLocalKey';
                            notification.open({
                                duration: 10,
                                description: <div>
                                    <div style={{marginBottom: 20}}>Desabilite seu bloqueador de Popups ou acesse o link abaixo</div>
                                    <a href={props.course.redirect_link}> {props.course.redirect_link} </a>
                                </div>,
                                key,
                                placement: 'topRight'
                            });
                        } else {
                            newWin.focus()
                        }
                    }
                    document.getElementById('scrollDivDrawer').scrollTop = 0;
                    message.success('Você inscreveu-se neste curso!');
                }, async (e) => {
                    setLoading(false);
                    message.error('Oops!', e.message || "Não foi possível se candidatar-se a curso, tente novamente");
                    console.log(e)
                });
            } else {
                setAlertRegisterCurriculum(true)
            }
        } else {
            setRegisterModal('pre-login')
        }
    };


    const handleCancel = async () => {
        setVisible(false);
        setLoading(true);
        api.post(`courses/unsubscribe/${props.course.course_id}`, null,async (res) => {
            setStatus('canceled');
            setLoading(false);
            if(props.removeFromList) props.removeFromList();
            props.setVisible(false);
            message.success('Você cancelou sua inscrição!');
        }, async (e) => {
            setLoading(false);
            message.error(e.message || "Não foi possível se cancelar sua inscrição do curso, tente novamente");
            console.log(e)
        });
    };


    const getLinks = () => {
        let links = [];
        let text = props.course?.subscription_message;
        if(text) {
            var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            text?.replace(urlRegex, function(url) {
                links.push(url);
            });
            setLinks(links)
        }
    }

    const getDescriptionLinks = () => {
        let links = [];
        let text = props.course?.description;
        if(text) {
            var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
            text?.replace(urlRegex, function(url) {
                links.push(url);
            });
            setLinks2(links)
        }
    }

    const handleCourseCity = (item) => {
        handleApply(item.course_city_id);
        setCourseCityModal(false);
    }

    useEffect(() => {
        if(props.course?.subscription_message){
            getLinks()
        }
        if(props.course?.description) {
            getDescriptionLinks()
        }
        if(props.course?.subscription_status) {
            setStatus(props.course?.subscription_status);
        }

    },[props.loading, props.course?.subscription_status])

    return (
        <>
            <Modal
                visible={courseCityModal}
                footer={null}
                closable={false}
                bodyStyle={{padding: 0}}
                width={'80%'}
                onCancel={() => {setCourseCityModal(false)}}
                className={'registerModalWidth'}
                destroyOnClose={true}
                style={{maxWidth: '550px'}}
            >
                <div className='d-flex justify-content-between align-items-center' style={{padding: 15, background: '#e4e4e44d'}}>
                    <h3 style={{color: '#5d5d5d', fontSize: 20, margin: 0}}> Selecione o curso/cidade </h3>
                    <div onClick={() => {setCourseCityModal(false)}}>
                        <Icon type="close" className='hoverIcon'/>
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', borderRadius: 10, overflow:'auto', maxHeight:530}}>
                    {props.course?.course_cities?.map((item, index) =>
                        <div key={index} className='select-business'>
                            <div style={{display: 'flex', flexFlow: 'column', flex: 1}}>
                                <span style={{color: "#656262", fontSize: 19}}> {item.description} </span>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Icon type="environment" className='hoverIcon' style={{marginRight: 3, fontSize: 17, color: "gray"}}/>
                                    <span style={{color: "#9e9e9e"}}> {item.city_name}</span>
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => handleCourseCity(item)}  className="gx-btn" style={{backgroundColor: '#68bb23', borderColor: "#68bb23", color: 'white', fontWeight: 'bold'}} block>
                                    Inscrever-se
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>

            <ModalAlertRegisterCurriculum visible={alertRegisterCurriculum} close={() => setAlertRegisterCurriculum(false)}/>
            <Drawer
                className="profile-drawer course-drawer"
                placement="right"
                closable={false}
                style={{overflow: `undefined`}}
                onClose={() => {props.setVisible(false); setStatus('unsub')} }
                visible={props.visible}
            >
                <RegisterPageModal profile={'worker'} visible={registerModal === 'login'} type={registerModal} setVisible={() => setRegisterModal(false)} />
                <Modal className={'requestModal'} width={620} visible={registerModal !== false} title={'Inscrever no Curso'} onOk={() => setRegisterModal('login')} onCancel={() => setRegisterModal(false)} okText={'Cadastrar pelo Site'}>
                    <div>
                        <span style={{fontSize: 17, fontWeight: 'bold'}}> Se interessou por este curso? </span> <br/> <br/>
                        <span style={{fontSize: 15}}>Faça o cadastro em nossa plataforma e se inscreva! </span><br/> <br/>

                        <Row>
                            <Col xs={24} md={8} style={{textAlign: 'center'}}>
                                <img style={{height: 140}} src={Images.app_img}/>
                            </Col>
                            <Col xs={24} md={16} style={{margin: 'auto', marginBottom: 20}}>
                                <div style={{textAlign: 'center'}}>
                                    <span style={{fontSize: 15}}> Você tambem pode fazer isso pelo nosso aplicativo! </span>

                                    <div style={{display: 'flex', flexFlow: 'column', marginTop: 20, justifyContent: 'center', }}>
                                        <Button onClick={() => window.redirectWorker(Env.worker_play)} style={{height: 35, marginBottom: 10, boxShadow: '0 2px 0 rgba(0, 0, 0, 0.06)'}}>
                                            <div>
                                                <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.gplay_icon}/> Disponível no <strong> Google Play </strong>
                                            </div>
                                        </Button>

                                        <Button onClick={() => window.redirectWorker(Env.worker_apple)} style={{height: 35,  boxShadow: 'rgba(0, 0, 0, 0.06) 0px 0px 3px 2px'}}>
                                            <div>
                                                <img style={{height: 20, marginRight: 10, marginBottom: 2}} src={Images.apple_icon}/> Disponível na <strong> App Store </strong>
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>

                <div style={{overflow: 'auto', padding: 24, height: window.innerHeight}} id={'scrollDivDrawer'}>
                    <Spin spinning={props.loading || loading} tip="Carregando...">
                        <React.Fragment>
                            <div className={'mainComponentPanel'} style={{backgroundColor: "white"}}>
                                <div>
                                    <div style={{marginBottom: 20}}>
                                        <span style={{fontSize: 19, fontWeight: 'bold'}}> {props.course?.title}  </span>
                                    </div>

                                    {props.course?.image && <div style={{width: "100%", marginBottom: 20, display: 'flex', justifyContent: 'center'}}>
                                        <img src={props.course?.image} style={{maxWidth: "100%", borderRadius: 5, boxShadow: "1px 1px 5px 0px #75757540"}}/>
                                    </div>}

                                    {props.course?.video && <div className='iframeDiv'>
                                        <iframe src={props.course?.video}
                                                allow='autoplay; encrypted-media'
                                                allowFullScreen
                                                title='video'
                                        />
                                    </div>}

                                    {(status !== 'unsub' && status !== 'canceled') &&
                                        <>
                                            <div style={{ padding: 10, paddingHorizontal: 15, margin: 5,  borderWidth:1, backgroundColor: '#edffe6', borderColor: 'green', borderRadius:5, alignItems: "center"}}>
                                                <Icon type={'notification'} size={25}/>
                                                <span style={{fontSize: 17, color: 'green'}}> Mensagem do curso </span>

                                                <div style={{flex: 1, marginTop: 10}}>
                                                    <span>{props.course?.subscription_message || 'Você está inscrito neste curso'}</span>
                                                </div>
                                            </div>

                                            {links.length > 0 &&
                                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                                    <div style={{flexDirection: "row", flexWrap: "wrap", display: 'flex', justifyContent: "flex-start"}}>
                                                        {links.map((item, index) =>
                                                            <div style={{padding: 5, paddingRight: 8, textAlign: 'center', paddingLeft: 8, backgroundColor: "#eeecec", color: '#9e9e9e', borderRadius: 5, margin: 5}} key={index} >
                                                                <a target={'_blank'} href={item}>
                                                                    {item}
                                                                </a>
                                                                <FaHandPointer style={{marginLeft: 5}}/>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>}
                                        </>
                                    }

                                    <Row style={{margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} lg={12}  className='vacancyResumeTags'>
                                            <FaIdCardAlt/>
                                            <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                                <span className='tagQuestion'> Curso </span>
                                                <span style={{textOverflow: 'ellipsis', color: "#52c41a"}}> {props.course?.title} </span>
                                                <div style={{color: "gray"}}>{props.course?.category_name}</div>

                                                {status !== 'canceled' && props.course?.course_cities?.map((item,index) =>
                                                    (item.course_city_id === props.course.subscription_city) && <div>
                                                        <div style={{display: 'flex',  width: "100%"}}>
                                                            <strong style={{marginRight: 5}}> Curso escolhido: </strong>
                                                            <div style={{color: "gray"}}> {item.description} - {item.city_name}</div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </Col>
                                        {props.course?.courses_quantity > 1 &&
                                        <Col xs={24} lg={12}  className='vacancyResumeTags'>
                                            <FaSortAmountUp/>
                                            <div style={{display: 'flex', flexFlow: 'column', width: "100%"}}>
                                                <span className='tagQuestion'> Quantidade </span>
                                                <div style={{color: "gray"}}>{props.course?.courses_quantity} Cursos</div>
                                            </div>
                                        </Col>
                                        }
                                    </Row>

                                    <Row  style={{backgroundColor: "#fafafad4", margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                                            <FaCodepen/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Organizadores do curso </span>
                                                <span className='tagAnswer'> {props?.course?.creators.map((item, index) =>
                                                    <span key={index}>
                                                        {item.toUpperCase()}
                                                        {props?.course?.creators.length-1 === index? '.' : ', '}
                                                    </span>
                                                )}
                                                </span>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaBriefcase/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Quantidade de vagas </span>
                                                <span className='tagAnswer'> {props?.course?.subscriptions_limit? props?.course?.subscriptions_limit: 'Sem limite'} </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaDollarSign/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Custo  </span>
                                                <span className='tagAnswer'> {props?.course?.fee? globalMaskReal(props?.course?.fee) : "R$ 0,00 (Grátis)"} </span>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaClock/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Duração </span>
                                                <span className='tagAnswer'> {props?.course?.hours_total? (props?.course?.hours_total + ' horas') : 'Não informado'}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        {((!props.course?.subscription_limit_date || moment(props.course?.subscription_limit_date).isSameOrAfter(moment().format('YYYY-MM-DD'))) && !props.course?.closed) ?
                                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                                <FaRegCalendarAlt/>
                                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                                    <span className='tagQuestion'> Data limite para inscrição </span>
                                                    <span
                                                        className='tagAnswer'> {props?.course?.subscription_limit_date ? moment(props.course?.subscription_limit_date).format("DD/MM/YYYY") : 'Sem limite'}</span>
                                                </div>
                                            </Col>
                                            :
                                            <Col xs={24} lg={12} className='vacancyResumeTags'>
                                                <FaRegCalendarAlt/>
                                                <div style={{display: 'flex', flexFlow: 'column'}}>
                                                    <span className='tagQuestion'> Data limite para inscrição </span>
                                                    <span style={{color: '#c33434'}} className='tagAnswer'> {moment(props.course?.subscription_limit_date).format("DD/MM/YYYY")} (Inscrições encerradas)</span>
                                                </div>
                                            </Col>
                                        }

                                        <Col xs={24} lg={12} className='vacancyResumeTags'>
                                            <FaRegCalendarAlt/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Começo do curso </span>
                                                <span
                                                    className='tagAnswer'> {moment(props?.course?.start_at).format("DD/MM/YYYY")}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{backgroundColor: "#fafafad4", margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaRegThumbsUp/>
                                            <div style={{display: 'flex',flex: 1, flexFlow: 'column'}}>
                                                <span className='tagQuestion'> O que você aprenderá  </span>
                                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                                    {props.course?.what_to_learn.map((item, index) =>
                                                        <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#dbfae2 ", border: "1px solid #1f9738", color: "#1f9738", borderRadius: 5, margin: 5}}>
                                                        <span>
                                                             {_.capitalize(item)}
                                                        </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24}  className='vacancyResumeTags'>
                                            <FaRegFileAlt/>
                                            <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Descrição do curso </span>
                                                <span className='tagAnswer'> {props?.course?.description? props.course?.description : "Nenhuma descrição inserida" } </span>
                                            </div>
                                        </Col>
                                    </Row>

                                    {props.course?.notice &&<Row style={{margin: 0, padding: 10, paddingTop: 0, display: 'flex', alignItems: 'center', color: '#005c9f', justifyContent: 'flex-end', paddingBottom: 15}}>
                                        <FaLongArrowAltRight/>
                                        <div style={{display: 'flex',  flexFlow: 'column'}}>
                                            <a className='tagQuestion' target={'_blank'} href={props.course?.notice}> Acessar edital do curso </a>
                                        </div>
                                    </Row>}

                                    {links2.length > 0 &&
                                        <div style={{display: 'flex', flexFlow: 'column'}}>
                                            <div style={{flexDirection: "row", flexWrap: "wrap", display: 'flex', paddingLeft: 25, justifyContent: "flex-start"}}>
                                                {links2.map((item, index) =>
                                                    <div style={{padding: 5, paddingRight: 8, textAlign: 'center', paddingLeft: 8, backgroundColor: "#eeecec", color: '#9e9e9e', borderRadius: 5, margin: 5}} key={index} >
                                                        <a target={'_blank'} href={item}>
                                                            {item}
                                                        </a>
                                                        <FaHandPointer style={{marginLeft: 5}}/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}

                                    <Row style={{backgroundColor: "#fafafad4", margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaRegMap/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Endereço a ser realizado </span>
                                                {(props.course?.location)?
                                                    <div>
                                                        <span> <strong> Rua - </strong> {props.course?.location.street} </span> <br/>
                                                        <span> <strong> Nº - </strong> {props.course?.location.street_number} </span><br/>
                                                        <span> <strong> Bairro - </strong> {props.course?.location.district} </span><br/>
                                                        <span> <strong> Cidade - </strong> {props.course?.location.city} </span>
                                                    </div>
                                                    :(props.course?.course_type === 'online'?
                                                            <div>
                                                                <span> Online </span>
                                                            </div>
                                                            :
                                                            <div>
                                                                <span> Presencial </span>
                                                            </div>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div style={{width: "100%"}}>
                                    <Row style={{margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaUsers/>
                                            <div style={{display: 'flex',flex: 1, flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Para quem é este curso </span>
                                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                                    {props.course?.for_who?.map((item, index) =>
                                                        <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#e7f3fb ", border: "1px solid #005c9f", color: "#005c9f", borderRadius: 5, margin: 5}}>
                                                        <span>
                                                             {_.capitalize(item)}
                                                        </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{backgroundColor: "#fafafad4", margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} className='vacancyResumeTags'>
                                            <FaTools/>
                                            <div style={{display: 'flex', flex: 1, flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Requisitos </span>
                                                <div style={{display: "flex", flexWrap: 'wrap'}}>
                                                    {props.course?.requisites?.map((item, index) =>
                                                        <div key={index} style={{padding: 3, paddingRight: 8, paddingLeft: 8, backgroundColor: "#e7f3fb ", border: "1px solid #005c9f", color: "#005c9f", borderRadius: 5, margin: 5}}>
                                                        <span>
                                                             {_.capitalize(item)}
                                                        </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{margin: 0, padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                        <Col xs={24} lg={12} className='vacancyResumeTags' >
                                            <FaPhone/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Telefone para contato </span>
                                                <span className='tagAnswer'> {props?.course?.contact_phones.length > 0 ? maskPhone(props?.course?.contact_phones[0]) : 'Nenhum informado'} </span>
                                            </div>
                                        </Col>

                                        <Col xs={24} lg={12}  className='vacancyResumeTags'>
                                            <MdMail/>
                                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                                <span className='tagQuestion'> Email para contato </span>
                                                <span className='tagAnswer'>
                                                    {props?.course?.contact_emails.length > 0 ? props?.course?.contact_emails[0].toLowerCase() : 'Nenhum informado'} </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            {!props.course?.info_only &&<Row style={{padding: 10, paddingTop: 15, paddingBottom: 15}}>
                                {((status === 'unsub' || status === 'canceled') && ((!props.course?.subscription_limit_date || moment(props.course?.subscription_limit_date).isSameOrAfter(moment().format('YYYY-MM-DD'))) && !props.course?.closed))  ?
                                    <Button onClick={() => handleApply()} className="gx-btn" style={{backgroundColor: '#68bb23', borderColor: "#68bb23", color: 'white', fontWeight: 'bold'}} block>
                                        Inscrever-se no curso
                                    </Button>  : (status !== 'unsub' && status !== 'canceled') && <Button onClick={() => handleCancel()} className="gx-btn" style={{backgroundColor: "#ff7c72", borderColor: '#ff7c72', color: 'white', fontWeight: 'bold'}} block>
                                    Cancelar minha inscrição
                                </Button>}
                            </Row>}

                        </React.Fragment>
                    </Spin>
                </div>

            </Drawer>
        </>
    );
};

WorkerCourseDrawer.propTypes = {
    cboId: PropTypes.any,
    id: PropTypes.any,
    setVisible: PropTypes.any,
    visible: PropTypes.any,
    recruited: PropTypes.any,
    favorite: PropTypes.any,
    handleFavorite: PropTypes.any,
    disregardCurriculum: PropTypes.any
};

export default WorkerCourseDrawer;
