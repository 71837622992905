import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Divider, Dropdown, Form, Icon, Input, Menu, Spin, TreeSelect} from "antd";
import {Bounce} from "react-reveal";
import {useHistory} from "react-router-dom";

import useAuth from "../../../../Hooks/Auth";
import useLocalStorage from "../../../../Hooks/Storage";
import SubMenu from "antd/es/menu/SubMenu";
import {filterWorkerSearch} from "../../../../Helpers/Functions";

const SideBarFilter = (props) => {
    const history = useHistory();
    const {getFieldDecorator, getFieldsValue, getFieldError, setFieldsValue} = props.form;
    const {isLogged} = useAuth();
    const [loading, setLoading] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const lastWorkerSearch = useLocalStorage('lastWorkerSearch');
    const [value, setValue] = useState("");


    const onSelect = (value, text) => {
        setValue(text);
        setFieldsValue({occupation_id: value});
        lastWorkerSearch.setPrimitive(value);
    };

    const getOccupationName = () => {
        let id = props.occupation_id? props.occupation_id : (lastWorkerSearch.getPrimitive()? lastWorkerSearch.getPrimitive() : 27);
        props.servicesTreeList.forEach((item) => {
            item.occupations.forEach((subItem) => {
                if(subItem.id === Number(id)) {
                    setValue(subItem.name)
                }
            })
        });
    }

    const handleSearch = () => {
        return filterWorkerSearch(value, props.servicesTreeList);
    }

    useEffect(() => {
        setTimeout(() => {setLoading(false);},[1500])
        getOccupationName();
    },[props.servicesTreeList]);

    return (
        <>
            <div style={{minHeight: 300, paddingTop: 22}} className='sidebarCol'>
                {loading? <div style={{width: '100%', textAlign: 'center', marginTop: 30}}> <Spin/> </div> :
                    <div>
                <label style={{fontSize: 18}}> Qual serviço está buscando? </label>
                <Form.Item required={true} help={getFieldError('occupation_id')}>
                    <Dropdown trigger={'click'} overlay={<Menu className={'searchWorkerMenu'}>
                        {value !== "" ?
                            handleSearch().map((item, index) =>
                                <Menu.Item onClick={() => onSelect(item.id, item.name)} key={index}>{item.name}</Menu.Item>
                            )
                        :
                            props.servicesTreeList.map((item, index) =>
                                <SubMenu key={index} title={item.name}>
                                    {item.occupations.map((subItem, subIndex) =>
                                        <Menu.Item onClick={() => onSelect(subItem.id, subItem.name)} key={subIndex}>{subItem.name}</Menu.Item>
                                    )}
                                </SubMenu>
                            )
                        }
                        </Menu>}>
                        <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder={'Selecione o serviço desejado'}/>
                    </Dropdown>
                </Form.Item>

                <div style={{display: 'none'}}>
                    {getFieldDecorator('occupation_id', {
                        initialValue: props.occupation_id? props.occupation_id : (lastWorkerSearch.getPrimitive()? lastWorkerSearch.getPrimitive() : 27),
                        rules: [
                            {
                                required: true,
                                message:  'Escolha um serviço',
                            },
                        ]
                    })(<Input/>)}
                </div>

                <label style={{fontSize: 18}}> Tem um nome em mente? </label>
                <Form.Item style={{marginBottom: '16px'}}>
                    {getFieldDecorator('worker', {
                        initialValue: props.worker&& props.worker,
                        rules: []
                    })( <Input style={{marginTop: 20, fontSize: 16, marginBottom: 20}} size={'large'} placeholder={'Nome'} suffix={<Icon type="user" className={'primary-sub-color'} style={{fontSize: '16px' }} />}/>)}
                </Form.Item>

                <Button icon={'search'} className='searchButtonSubPage' onClick={() => props.searchAction()}> Buscar </Button>
                    </div>}
            </div>
        </>

    );
};

SideBarFilter.propTypes = {
    searchAction: PropTypes.any,
    occupation_id: PropTypes.any,
    servicesTreeList: PropTypes.any,
    worker: PropTypes.any
};

export default SideBarFilter;
