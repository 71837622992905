import React from 'react';
import {Col, Icon, Row} from 'antd';
import {Slide} from "react-reveal";
import useAuth from "../../../../Hooks/Auth";
import {useHistory} from "react-router-dom";

import {Texts} from "../../../../Helpers/Texts";
import useVerifyServices from "../../../../Hooks/VerifyServices";
import {Images} from "../../../../Helpers/Images";

const HowToComponent = () => {
    const history = useHistory();
    const {isLogged} = useAuth();
    const {hasAll, hasOnlyOne} = useVerifyServices();

    return (
        <Row style={{minHeight: '400px', display: 'flex', justifyContent: 'center', marginBottom: '150px', marginTop: '150px'}}>
            <Col xs={23} lg={22} xl={18} xxl={15}>
                <Row className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
                    <h1 className={'title title-line'}> {Texts.home_page.howTo.title1} </h1>
                    <div style={{maxWidth: 600, margin: 'auto'}}>
                        {Texts.home_page.howTo.subTitle1}
                    </div>
                </Row>

                 <Row>
                    <Slide left>
                        <Col className={'howToCards'} span={24}>
                            <div className={'imgDiv'}> <img src={Images.home.how_to1}/></div>
                            <div>
                                <h2> {Texts.home_page.howTo.card_title1} </h2>
                                <span> {Texts.home_page.howTo.card_subTitle1} </span>
                                <strong onClick={() => history.push(Texts.home_page.howTo.link1)}> Saiba mais <Icon type={'right'}/></strong>
                            </div>
                        </Col>
                    </Slide>
                    <Slide delay={200} right>
                        <Col className={'howToCards'} span={24}>
                            <div className={'imgDiv'}> <img src={Images.home.how_to2}/></div>
                            <div>
                                <h2> {Texts.home_page.howTo.card_title2} </h2>
                                <span> {Texts.home_page.howTo.card_subTitle2} </span>
                                <strong  onClick={() => history.push(Texts.home_page.howTo.link2)}> Saiba mais <Icon type={'right'}/></strong>
                            </div>
                        </Col>
                    </Slide>
                     {(hasOnlyOne() || hasAll()) &&<Slide delay={400} left>
                        <Col className={'howToCards'} span={24}>
                            <div className={'imgDiv'}> <img src={Images.home.how_to3}/></div>
                            <div>
                                <h2> {Texts.home_page.howTo.card_title3}</h2>
                                <span> {Texts.home_page.howTo.card_subTitle3} </span>
                                <strong  onClick={() => history.push(Texts.home_page.howTo.link3)} > Saiba mais <Icon type={'right'}/></strong>
                            </div>
                        </Col>
                    </Slide>}
                </Row>
            </Col>
        </Row>
    );
};

HowToComponent.propTypes = {};

export default HowToComponent;
