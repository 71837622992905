import React, {useEffect} from 'react';
import {Carousel, Col, Row} from "antd";
import {Images} from "../../../../Helpers/Images";
import {Texts} from "../../../../Helpers/Texts";

const PartnersComponent = () => {

    useEffect(() => {

    },[]);

    return (<Row style={{marginTop: 50, display: 'flex', flexFlow: 'column'}}>
    {/*    <Col xs={24} md={24} lg={24} xxl={24} style={{textAlign: 'center'}}>
            <div className={'titlesSeparator'} style={{marginTop: 0}}><div>  Oferecimento </div>  </div>

            <div className={'partnersTop'} style={{marginBottom: 0}}>
                <img style={{maxHeight: 50}} src={Images.logo}/>
            </div>
        </Col>
*/}
        <Row className='headerHowTo' style={{padding: 5, textAlign: 'center'}}>
            <h1 className={'title title-line'}> Parceiros </h1>
        </Row>

        <Col xs={24} sm={24} md={24} lg={22} xl={20} xxl={17} style={{margin: 'auto'}}>
            <Carousel autoplay={true}  className={'partnersCarousel'}>
                <Row gutter={[0,12]}>
                    <Col xs={12} md={6}> <img src={Images.partners.partner1}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner2}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner3}/></Col>
                </Row>

                <Row gutter={[0,12]}>
                    <Col xs={12} md={6}> <img src={Images.partners.partner4}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner5}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner6}/></Col>
                </Row>

                <Row gutter={[0,12]}>
                    <Col xs={12} md={6}> <img src={Images.partners.partner7}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner8}/></Col>
                    <Col xs={12} md={6}> <img src={Images.partners.partner9}/></Col>
                </Row>
            </Carousel>
        </Col>

    </Row>)
};
export default PartnersComponent;
